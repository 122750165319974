export interface VerificationResult {
  word: string
  claim: string
  predicted: string
  in_wiki: string
  selected_evidences?: Array<{
    title: string
    text: string
    line_idx: number
    in_intro: boolean
  }>
}

export interface VerificationRequest {
  word: string
  claim: string
}

export interface ProgressMessage {
  type: 'progress'
  message: string
}

export interface ResultMessage {
  type: 'result'
  data: VerificationResult
}

export interface ErrorMessage {
  type: 'error'
  message: string
}

export type WebSocketMessage = ProgressMessage | ResultMessage | ErrorMessage

export function verifyDefinitionWS(request: VerificationRequest, onMessage: (message: WebSocketMessage) => void): WebSocket {
  const ws = new WebSocket('wss://tools.ellngr.com/api/verification/verify-definition/ws')

  ws.onopen = () => {
    ws.send(JSON.stringify(request))
  }

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data) as WebSocketMessage
    onMessage(message)
  }

  ws.onerror = () => {
    onMessage({ type: 'error', message: 'WebSocket error occurred' })
  }
    return ws
}