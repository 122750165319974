import Page404 from "../pages/page404";
import ErrorLayout from "../layouts/error";
import HomePage from "../pages/home";
import BaseLayout from "../layouts/baseLayout";
import ToolLayout from "../layouts/toolLayout";
import RandomGenerator from "../pages/randomGenerator";
import JsonEditor from "../pages/json";
import Contact from "../pages/contact";
import PrivacyPolicy from "../pages/privacyPolicy";
import Terms from "../pages/terms";
import ApiTest from "../pages/apiTest";
import LanguageLayout from "../layouts/language";
import TextSummarizer from "../pages/textSummarization";
import FactSplitter from "../pages/factSplit";
import DefinitionVerification from "../pages/definitionVerification";
import ClaimVerification from "../pages/claimVerification";


const routes = [
  {
    path: '/',
    element: <LanguageLayout />,
    children: [
      {
        path: ':lng',
        element: <BaseLayout />,
        children: [
          { path: '', element: <HomePage /> },
        ],
      },
      {
        path: ':lng',
        element: <BaseLayout withPopover={true}/>,
        children: [
          { path: 'contact', element: <Contact /> },
          { path: 'privacy-policy', element: <PrivacyPolicy /> },
          { path: 'terms', element: <Terms /> },
          { path: 'api-test', element: <ApiTest /> },
        ],
      },
      {
        path: ':lng',
        element: <ToolLayout />,
        children: [
          { path: 'json-editor', element: <JsonEditor /> },
          { path: 'random-generator', element: <RandomGenerator /> },
          { path: 'fact-split', element: <FactSplitter /> },
          { path: 'text-summarizer', element: <TextSummarizer /> },
          { path: 'verify-definition', element: <DefinitionVerification /> },
          { path: 'verify-claim', element: <ClaimVerification /> },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <ErrorLayout />,
    children: [
      { path: '*', element: <Page404 /> },
    ],
  },
];

export default routes;
