export interface VerificationResult {
  claim: string
  predicted: string
  factuality?: number
  in_wiki: string
  atoms?: Array<{
    atom: string
    predicted: string
    selected_evids: Array<{
      title: string
      line_idx: number
      text: string
      sim: number
    }>
  }>
}

export interface VerificationRequest {
  claim: string
}

export interface ProgressMessage {
  type: 'progress'
  message: string
}

export interface ResultMessage {
  type: 'result'
  data: VerificationResult
}

export interface ErrorMessage {
  type: 'error'
  message: string
}

export type WebSocketMessage = ProgressMessage | ResultMessage | ErrorMessage

export function verifyStatementWS(request: VerificationRequest, onMessage: (message: WebSocketMessage) => void): WebSocket {
  const ws = new WebSocket('wss://tools.ellngr.com/api/verification/verify-statement/ws')

  ws.onopen = () => {
    ws.send(JSON.stringify(request))
  }

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data) as WebSocketMessage
    onMessage(message)
  }

  ws.onerror = () => {
    onMessage({ type: 'error', message: 'WebSocket error occurred' })
  }
    return ws
}